// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-basic-page-js": () => import("./../../src/templates/basic-page.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-blog-index-page-js": () => import("./../../src/templates/blog-index-page.js" /* webpackChunkName: "component---src-templates-blog-index-page-js" */),
  "component---src-templates-blog-post-page-js": () => import("./../../src/templates/blog-post-page.js" /* webpackChunkName: "component---src-templates-blog-post-page-js" */),
  "component---src-templates-careers-page-js": () => import("./../../src/templates/careers-page.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-dreem-headband-page-js": () => import("./../../src/templates/dreem-headband-page.js" /* webpackChunkName: "component---src-templates-dreem-headband-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-neurologists-page-js": () => import("./../../src/templates/neurologists-page.js" /* webpackChunkName: "component---src-templates-neurologists-page-js" */),
  "component---src-templates-news-index-page-js": () => import("./../../src/templates/news-index-page.js" /* webpackChunkName: "component---src-templates-news-index-page-js" */),
  "component---src-templates-our-team-page-js": () => import("./../../src/templates/our-team-page.js" /* webpackChunkName: "component---src-templates-our-team-page-js" */),
  "component---src-templates-press-release-page-js": () => import("./../../src/templates/press-release-page.js" /* webpackChunkName: "component---src-templates-press-release-page-js" */),
  "component---src-templates-products-services-page-js": () => import("./../../src/templates/products-services-page.js" /* webpackChunkName: "component---src-templates-products-services-page-js" */),
  "component---src-templates-publications-index-page-js": () => import("./../../src/templates/publications-index-page.js" /* webpackChunkName: "component---src-templates-publications-index-page-js" */),
  "component---src-templates-resources-page-js": () => import("./../../src/templates/resources-page.js" /* webpackChunkName: "component---src-templates-resources-page-js" */),
  "component---src-templates-software-index-page-js": () => import("./../../src/templates/software-index-page.js" /* webpackChunkName: "component---src-templates-software-index-page-js" */)
}

